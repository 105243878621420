import ApiCmsPage from 'types/api/CmsPage';
import { ApiResources } from 'types/apiResources';
import { Endpoints } from 'types/endpoints';
import Element from 'types/redux/Element';

import CmsPage from 'models/CmsPage';

import { ELEMENT_MARKUP } from 'consts/redux';
import { withVariables } from 'utils/string';
import { request } from 'services/Api';

export interface ListParams {}
export const list = async (params: ListParams): Promise<CmsPage[]> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicCmsPages, params),
        cache: {
            enabled: false,
        },
    });

    const data = response?.payload?.data;
    const resources = data && data[ApiResources.CmsPages];

    if(!data || !resources) {
        throw new Error('Not Found');
    }

    return resources.map(resource => new CmsPage(resource));
};


export interface SingleParams {
    slug: string;
}
export const single = async (params: SingleParams): Promise<Element<CmsPage>> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicCmsPage, params),
        cache: {
            enabled: false,
        },
    });


    const data = response?.payload?.data;
    const resource: ApiCmsPage = data && data[ApiResources.CmsPage];

    if(!data || !resource) {
        throw new Error('Not Found');
    }

    return {
        ...ELEMENT_MARKUP,
        isLoaded: true,
        data: new CmsPage(resource),
    };
};
