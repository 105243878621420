import ApiCmsPage from 'types/api/CmsPage';

import CmsSection from 'models/CmsSection';
import Model from 'models/Model';

export interface LocaleData {
    id: string;
    title: string;
    description?: string;
    keywords?: string;
}

export default class CmsPage implements Model {
    id: string;
    name: string;
    slug: string;
    enabled: boolean;
    head: string;
    locale: LocaleData;
    sections: CmsSection[];
    indexingEnabled: boolean;

    constructor(data: ApiCmsPage) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.head = data.head;
        this.indexingEnabled = data.indexingEnabled;
        this.locale = data.locale && {
            id: data.locale.id,
            title: data.locale.title,
            description: data.locale.description,
            keywords: data.locale.keywords,
        };
        this.sections = Array.isArray(data.sections)
            ? data.sections.map(section => new CmsSection(section))
            : [];
    }
}

