import { FunctionComponent, useContext } from 'react';
import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';

import DeviceContext from 'components/context/Device';

import { Props } from './types';

const LayoutHead: FunctionComponent<Props> = ({
    title,
    titleSuffix,
    description,
    keywords,
    children,
    canonical,
    robots,
    ogType,
    ogImage,
    cmsPageIndexingEnabled,
    pageRelations,
}) => {
    const deviceContext = useContext(DeviceContext);
    const { publicRuntimeConfig } = getConfig();
    const router = useRouter();

    const canonicalUrl = (): string => {
        if (canonical) {
            return canonical;
        }

        return router.asPath.split('?')[0];
    };

    const noIndexing = publicRuntimeConfig.NO_INDEXING;

    return ((
        <Head>
            {/* Favicons */}
            <link
                rel="apple-touch-icon"
                sizes="57x57"
                href="/static/favicon/apple-icon-57x57.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="60x60"
                href="/static/favicon/apple-icon-60x60.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="72x72"
                href="/static/favicon/apple-icon-72x72.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="76x76"
                href="/static/favicon/apple-icon-76x76.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="114x114"
                href="/static/favicon/apple-icon-114x114.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="120x120"
                href="/static/favicon/apple-icon-120x120.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="144x144"
                href="/static/favicon/apple-icon-144x144.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="152x152"
                href="/static/favicon/apple-icon-152x152.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/static/favicon/apple-icon-180x180.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="192x192"
                href="/static/favicon/android-icon-192x192.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/static/favicon/favicon-32x32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="96x96"
                href="/static/favicon/favicon-96x96.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/static/favicon/favicon-16x16.png"
            />
            <link
                rel="icon"
                href="/static/favicon/favicon.ico"
            />
            <link
                rel="manifest"
                href="/static/favicon/manifest.json"
            />
            <meta
                name="msapplication-TileColor"
                content="#ffffff"
            />
            <meta
                name="msapplication-TileImage"
                content="/static/favicon/ms-icon-144x144.png"
            />
            <meta
                name="theme-color"
                content="#ffffff"
            />

            {/* Seo */}
            {titleSuffix && (<title>{title} {titleSuffix}</title>)}
            {!titleSuffix && (<title>{title}</title>)}

            {description && (
                <meta
                    name="description"
                    content={description}
                />
            )}
            {keywords && (
                <meta
                    name="keywords"
                    content={keywords}
                />
            )}
            {/* {tags && (<div dangerouslySetInnerHTML={{ __html: tags }} />)} */}
            <link
                rel="canonical"
                href={`${publicRuntimeConfig.APP_URL}${canonicalUrl()}`}
            />
            {(noIndexing && !cmsPageIndexingEnabled) && (
                <meta
                    name="robots"
                    content="noindex, follow"
                />
            )}
            {(!noIndexing || cmsPageIndexingEnabled) && (
                <meta
                    name="robots"
                    content={robots}
                />
            )}
            <meta
                property="og:locale"
                content={publicRuntimeConfig.OG.locale}
            />
            <meta
                property="og:type"
                content={ogType}
            />
            <meta
                property="og:title"
                content={title}
            />
            {description && (
                <meta
                    property="og:description"
                    content={description}
                />
            )}
            <meta
                property="og:url"
                content={publicRuntimeConfig.APP_URL}
            />
            <meta
                property="og:site_name"
                content={publicRuntimeConfig.OG.siteName}
            />
            <meta
                property="og:image"
                content={ogImage ? ogImage : `${publicRuntimeConfig.APP_URL}${publicRuntimeConfig.OG.image}`}
            />
            {pageRelations?.prevRel && (
                <link
                    rel="prev"
                    href={`${publicRuntimeConfig.APP_URL}${pageRelations?.prevRel}`}
                />
            )}
            {pageRelations?.nextRel && (
                <link
                    rel="next"
                    href={`${publicRuntimeConfig.APP_URL}${pageRelations?.nextRel}`}
                />
            )}

            {pageRelations?.prevRel && (
                <link
                    rel="prev"
                    href={`${publicRuntimeConfig.APP_URL}${pageRelations?.prevRel}`}
                />
            )}
            {pageRelations?.nextRel && (
                <link
                    rel="next"
                    href={`${publicRuntimeConfig.APP_URL}${pageRelations?.nextRel}`}
                />
            )}

            {/* Misc */}
            {children}
            <link
                href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap"
                rel="stylesheet"
            />

            {/* Cookies */}
            {!deviceContext.isBot && (
                <>
                    <link
                        rel="stylesheet"
                        href="https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.5.1/dist/cookieconsent.css"
                    />
                    <script
                        src="https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.5.1/dist/cookieconsent.js"
                        async
                        data-cfasync="false"
                    />
                </>
            )}
        </Head>
    ));
};

export default LayoutHead;
